import React from 'react';

export const c = (...arr: (string|false|undefined|null)[]) => arr.filter((c) => c).join(' ');


export const split = (arr: any[], numberOfChunks: number) => {
  return chunk(arr, arr.length / numberOfChunks + 1);
};

export const chunk = (arr: any[], chunkSize: number) => {
  const res = [];
  for (let i = 0; i < arr.length; i += chunkSize) {
    const chunk = arr.slice(i, i + chunkSize);
    res.push(chunk);
  }
  return res;
};

export const handleize = function(value: string) {
  return value
    .toLowerCase()
    .replace(/[^a-z0-9]+/g, '-')
    .replace(/-$/, '')
    .replace(/^-/, '');
};

/**
* Adds a ripple given some parameters. Will also remove any existing ripples.
* @param element element to add ripple to
* @param event event for mouse coords
*/
export const addRipple = function(element: HTMLElement, event: React.MouseEvent) {
  const ripples = element.getElementsByClassName('ripple');
  if (ripples) {
    Array.from(ripples).forEach(el => {
      const createdAt = el.getAttribute('data-created-at');
      if (!createdAt || (parseInt(createdAt) + 1000) < Date.now()) { // ripple expires after 1 second
        el.remove && el.remove();
      }
    });
  }
  const diameter = Math.max(element.clientWidth, element.clientHeight);
  const radius = diameter / 2;

  const ripple = document.createElement('span');
  const elementRect = element.getBoundingClientRect();
  ripple.style.width = ripple.style.height = `${diameter}px`;
  ripple.style.left = `${event.clientX - (elementRect.left + radius)}px`;
  ripple.style.top = `${event.clientY - (elementRect.top + radius)}px`;
  ripple.classList.add('ripple');
  ripple.setAttribute('data-created-at', String(Date.now()));
  element.append(ripple);
};